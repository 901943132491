import 'lazysizes';
import Glide from '@glidejs/glide';
import logoScroller from '@keodesign/logo-scroller';


var html_classes = document.documentElement.classList;
html_classes.remove("no-js");
html_classes.add("js");
$().ready(function(){   
    setupSlideMenu();
    $('#map-click').click(function(){
        // shield the map on mobile for easy scrolling
        $(this).hide();
    });
    initGetQuote();
    logoScroller('.slider', ".card", "left", 0.125);
    glider('.glide');
    autoHover('.hoverable');
    linkScroller();
});

export function setupSlideMenu(){
    $('#mobile-menu, .slide-menu h3').click(function(){
        $("body").toggleClass('slide-menu-active');
    });

    $(".slide-menu").on('click','.expand',function(e) {
        e.stopPropagation();
        $(this).closest('li').toggleClass('expand-active');
    });

    $(".slide-menu li").each(function(index, element) {
        var $el = $(element);
        if ($el.find('ul').length > 0) {
            var expander = $('<span class="expand"><i class="nav-arrow"></i></span>');
            $el.append(expander);
            expander.click(function(e) {
                e.stopPropagation();
                $el.toggleClass('expand-active');
            });
        }
    });
}

//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener('lazybeforeunveil', function(e){
    var container = e.target;
    var bgUrl = container.dataset.bgUrl;
    if (bgUrl) {
        container.style.backgroundImage = "url('" + bgUrl + "')";
        delete container.dataset.bgUrl;
    }
});


export function initGetQuote() {
    var quote_wrapper = document.querySelector(".contact-card-wrapper");
    quote_wrapper.addEventListener("click", hideQuote);

    function showQuote() {
        quote_wrapper.classList.add("active");
    }

    function hideQuote(e) {
        e = window.event || e;
        if (this === e.target) {
            // Should only fire if the quote wrapper was clicked
            // Without this, it would fire when a child is clicked due to event bubbling
            quote_wrapper.classList.remove("active");
        }
    }

    var get_a_quote_elm = document.querySelectorAll(".toggle-contact-form");
    for (var i = 0, len = get_a_quote_elm.length; i < len; i++) {
        get_a_quote_elm[i].addEventListener("click", showQuote);
    }
}


export function glider(selector){
    let container = document.querySelector(selector);
    if (container){
        let peekOffset = window.innerWidth / 4;
        let glide = null;
        window.addEventListener('load', function(){
            glide = new Glide(selector, {
                type: 'carousel',
                startAt: 1,
                focusAt: 0,
                perView: 1,
                gap:20,
                hoverpause: true,
                autoplay: 2000,
                peek: {
                    before: peekOffset,
                    after: peekOffset
                }
            })
            glide.mount();

            window.addEventListener('resize', function() {
                peekOffset = this.innerWidth / 4;
                glide.update({
                    peek: {
                        before: peekOffset,
                        after: peekOffset
                    }
                });
            });
            let slides = container.querySelectorAll('.glide__slide');
            slides.forEach(slide => { 
                let order = slide.dataset.order
                slide.addEventListener('click', () => {glide.go('='  + order)}) 
            });
        
        });
    }
}

export function autoHover(selector){
    
    let elements = document.querySelectorAll(selector);
    let i = 0;
    if (elements.length){
        const myInterval = setInterval(() =>  {
            autoCycle(i++ % elements.length)
        }, 1800);
        let myListener = document.addEventListener('mousemove', function() {
            clearInterval(myInterval);
            document.removeEventListener("mousemove", myListener, true);
            //when we detect mouse movement, cancel the autoHover
        });
    }
    
    //cycle through each region with click
    function autoCycle(i) 
    {
        elements[i].classList.add('hover');
        setTimeout( () => {
            elements[i].classList.remove('hover');
        }, 1700);
    
    }
}
export function linkScroller(){
    
    const checkLinkIsID = (url) =>{
        if (url.charAt(0) == '#'){
            console.log("link scroller checking url", url);
            document.querySelector(url).scrollIntoView({behavior: "smooth", block: "start"});
        
            return true;
        }
        return false;
    } 
    document.onclick = function (e) {
        console.log("document onclick called");
        e = e ||  window.event;
        e.path.forEach( (el) => {
            if(el.tagName == 'A'){
                if(checkLinkIsID(el.hash)){
                    e.preventDefault();
                    return false; // prevent default action and stop event propagation
                }
            }
            
        })
    
        
        
    };
}